<template>
  <v-container fluid>
    <!--Breadcrumbs-->
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <Breadcrumbs :breadcrumbs="breadcrumbs" />
        </v-col>
        <v-col cols="6">
          <v-container fluid>
            <v-row justify="end">
              <v-col cols="12" align="end">
                <v-btn
                  @click="hiddenFilters = !hiddenFilters"
                  class="font-weight-bold white--text"
                  color="primary"
                >
                  {{ hiddenFilters ? "Filter Users" : "Hide Filters" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-col class="collapsing-filters" v-show="!hiddenFilters" cols="12">
            <v-card>
              <v-row justify="space-around">
                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" cols="3 ">
                    <v-select
                      label="Status"
                      :items="statusFilterList"
                      clearable
                      v-model="statusFilter"
                      @change="filter"
                    >
                    </v-select>
                  </v-col>
                </v-expand-transition>

                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" cols="3">
                    <v-select
                      label="Role"
                      :items="roleFilterList"
                      clearable
                      v-model="roleFilter"
                      @change="filter"
                    >
                    </v-select>
                  </v-col>
                </v-expand-transition>

                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" align="end" cols="3">
                    <v-select
                      label="Reports"
                      :items="reportSortFilterList"
                      clearable
                      v-model="reportFilter"
                      @change="filter"
                    >
                    </v-select>
                  </v-col>
                </v-expand-transition>
              </v-row>

              <v-row justify="space-around">
                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" cols="3 ">
                    <v-text-field
                      v-model="emailSearch"
                      label="Search by e-mail"
                      class="mx-4"
                      clearable
                      @change="searchUsers"
                    ></v-text-field>
                  </v-col>
                </v-expand-transition>

                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" cols="3">
                    <v-text-field
                      v-model="nameSearch"
                      label="Search by name"
                      class="mx-4"
                      clearable
                      @change="searchUsers"
                    ></v-text-field>
                  </v-col>
                </v-expand-transition>

                <v-expand-transition>
                  <v-col v-show="!hiddenFilters" cols="3">
                    <v-text-field
                      v-model="planSearch"
                      label="Search by plan name"
                      class="mx-4"
                      clearable
                      @change="searchUsers"
                    ></v-text-field>
                  </v-col>
                </v-expand-transition>
              </v-row>

            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <data-table
      :headers="headers"
      :data="data"
      :loading="loading"
      :pageNumber="pageNumber"
      :paginate="paginate"
      :options="options"
    >
      <component
        :is="headerChildComponent"
        :refreshData="handleRefreshData"
      ></component>

    </data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Breadcrumbs from "../components/common/Breadcrumbs.vue";
import DataTable from "../components/common/DataTable.vue";
import DataTableHeader from "../components/common/DataTableHeader.vue";
import { itemsSpan } from "../helpers/index";
export default {
  components: {
    Breadcrumbs,
    DataTable,
    DataTableHeader,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Users",
          href: "/users",
        },
      ],
      headers: [
        { text: "Id", value: "id" },
        { text: "Registered at", value: "date_registered" },
        { value: "avatar", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Remaining reports", value: "remaining_reports" },
        { text: "Business", value: "is_business" },
        { text: "Affiliate", value: "is_affiliate" },
        { text: "Active", value: "is_active" },
        { text: "Generated Reports", value: "generated_reports" },
      ],
      headerChildComponent: "DataTableHeader",
      options: {
        rowClick: this.rowClick,
        avatar: ["avatar"],
        timestamps: ["date_registered"],
      },
      hiddenFilters: true,
      statusFilterList: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      roleFilterList: [
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "User",
          value: "user",
        },
        {
          text: "Staff",
          value: "staff",
        },
      ],
      reportSortFilterList: [
        {
          text: "Ascending",
          value: "asc",
        },
        {
          text: "Descending",
          value: "desc",
        },
      ],
      statusFilter: null,
      roleFilter: null,
      reportFilter: null,
      emailSearch: null,
      nameSearch: null,
      planSearch: null,
    };
  },
  computed: {
    ...mapState("users", ["data", "pageNumber", "loading"]),
    itemsSpan,
    usersPageNumber: {
      get() {
        return this.pageNumber;
      },
      set(value) {
        this.UPDATE_PAGE_NUMBER(value);
      },
    },
  },
  methods: {
    ...mapActions("users", ["getUsers", "getUsersSearch"]),
    ...mapMutations("users", ["UPDATE_PAGE_NUMBER"]),
    paginate(pageNumber) {
      this.usersPageNumber = pageNumber;
      if(this.emailSearch != null || this.nameSearch != null || this.planSearch != null)
        this.searchUsers();
      else
        this.filter();
    },
    rowClick(row) {
      this.$router.push({ name: "User", params: { id: row.id } });
    },
    filter() {
      this.getUsers({
        status: this.statusFilter,
        role: this.roleFilter,
        sort_by_remaining_reports: this.reportFilter,
      });
    },
    handleRefreshData() {
      this.UPDATE_PAGE_NUMBER(1);
      this.emailSearch = null;
      this.filter();
    },
    searchUsers() {
      
      this.getUsersSearch({
        email: this.emailSearch,
        name: this.nameSearch,
        user_plan: this.planSearch,
      });
    },
  },
  created() {
    this.filter();
  },
};
</script>

<style scoped>
.status-chip {
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: 120px;
}
.breadcrumb-link {
  color: #0000008a !important;
}
</style>
